import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/All Components/Navbar";
import Record from "./components/All Components/Record";
import PersonalAffirmation from "./components/All Components/PersonalAffirmation";
import Application from "./components/All Components/Application";
import OurTool from "./components/All Components/OurTool";
import AboutAffirmations from "./components/All Components/AboutAffirmations";
import Contact from "./components/All Components/Contact";
import Footer from "./components/All Components/Footer";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsConditions from "./pages/TermsAndCondtion";
import PaymentSuccess from "./components/All Components/PaymentSuccess";
import ScrollToTop from "./components/ScrollToTop";
import BlogsPage from "./components/Blogs/Blogs";
import BlogDetailPage from "./components/Blogs/BlogDetailPage";

function App() {
  const [audioFile2, setAudioFile2] = useState(null);
  const [recordedAudio2, setRecordedAudio2] = useState(null);
  const [volumePercentage2, setVolumePercentage2] = useState(100);
  const [isRecording2, setIsRecording2] = useState(false);
  const about = useRef(null);
  const contact = useRef(null);
  const bottomRef = useRef(null);
  const howItWorks = useRef(null);
  const blog = useRef(null);

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      const yOffset = -80; // Adjust this value based on your navbar height
      const y = sectionRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  
  const dragToApplication = (event) => {
    event.preventDefault();
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      switch (hash) {
        case '#about':
          scrollToSection(about);
          break;
        case '#how-it-works':
          scrollToSection(howItWorks);
          break;
        case '#start-creating':
          scrollToSection(bottomRef);
          break;
        case '#blog':
          scrollToSection(blog);
          break;
        case '#contact':
          scrollToSection(contact);
          break;
        default:
          break;
      }
    };

    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <Router>
      <Navbar
        dragToBlog={() => scrollToSection(blog)}
        dragToContact={() => scrollToSection(contact)}
        dragToAbout={() => scrollToSection(about)}
        startCreating={() => scrollToSection(bottomRef)}
        dragToHowItWorks={() => scrollToSection(howItWorks)}
      />
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Record />
              <PersonalAffirmation howItWorks={howItWorks} clickHere={dragToApplication} />
              <Application
                bottomRef={bottomRef}
                audioFile={audioFile2}
                setAudioFile={setAudioFile2}
                recordedAudio={recordedAudio2}
                setRecordedAudio={setRecordedAudio2}
                isRecording={isRecording2}
                setIsRecording={setIsRecording2}
                volumePercentage={volumePercentage2}
                setVolumePercentage={setVolumePercentage2}
              />
              <OurTool blog={blog} />
              <AboutAffirmations about={about} />
              <Contact contact={contact} />
            </>
          }
        />
        <Route path="/success" element={<PaymentSuccess/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />

        
        {/* Add the terms and conditions route when you create that component */}
        <Route path="/terms-and-conditions" element={<TermsConditions/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { APP_BASE_URL, REACT_APP_BASE_URL } from '../../config';

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [activeBlog, setActiveBlog] = useState(null);
  const defaultImage = 'https://deep-image.ai/blog/content/images/2024/04/e2912cf3-9b89-4092-a821-6f4978e95911-generated.webp';

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${REACT_APP_BASE_URL}/blogs`);
        if (response.data.success) {
          setBlogs(response.data.data);
          setActiveBlog(response.data.data[0]);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  const handleImageError = (event) => {
    event.target.src = defaultImage;
  };

  const handleBlogClick = (blogRef) => {
    if (blogRef.current) {
      blogRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="blog-page bg-gray-50 min-h-screen p-5">
      {/* Blog Header */}
      <div className="blog-header rounded-lg text-center mt-20 md:mt-24  mb-10"
          style={{ background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)'}}
      
      >
  <h1 className="blog-title text-3xl p-1 md:text-4xl font-bold text-pink-500 mb-4">
    Blogs
  </h1>
  <p className="blog-description text-lg p-1 md:text-xl text-gray-600">
    Discover our latest articles and in-depth insights across a range of topics. Stay ahead with fresh and inspiring reads.
  </p>
</div>


      {/* Blog Container */}
      <div className="blog-container flex flex-col lg:flex-row gap-10">
        {/* Active Blog */}
        <div className="active-blog flex-1 bg-white rounded-lg shadow-lg p-5">
          {activeBlog && (
            <>
              <div className="active-blog-image mb-4">
                <img
                  src={activeBlog?.images ? `${APP_BASE_URL}/${activeBlog.images}` : defaultImage}
                  alt={activeBlog.title}
                  onError={handleImageError}
                  className="w-full h-60 object-cover rounded-lg"
                />
              </div>
              <h3 className="active-blog-title text-2xl font-semibold text-pink-500 mb-4">
                {activeBlog.title}
              </h3>
              <div
                id="editor-containers"
                className="active-blog-description text-gray-700 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: activeBlog.content }}
              />
            </>
          )}
        </div>

        {/* Other Blogs */}
        <div className="other-blogs w-full lg:w-1/3">
          <Swiper
            direction="vertical"
            spaceBetween={20}
            slidesPerView="auto"
            className="blog-swiper"
          >
            {blogs.map((blog) => {
              const blogRef = React.createRef();
              return (
                <SwiperSlide key={blog._id}>
                  <div
                    ref={blogRef}
                    className="blog-card bg-white rounded-lg shadow-md p-4 mb-4 cursor-pointer hover:shadow-lg transition"
                    onClick={() => {
                      setActiveBlog(blog);
                      handleBlogClick(blogRef);
                    }}
                  >
                    <div className="blog-card-image mb-3">
                      <img
                        src={blog?.images ? `${APP_BASE_URL}/${blog.images}` : defaultImage}
                        alt={blog.title}
                        onError={handleImageError}
                        className="w-full h-40 object-cover rounded-md"
                      />
                    </div>
                    <h3 className="blog-card-title text-xl font-semibold text-pink-500 mb-2">
                      {blog.title}
                    </h3>
                    <div
                      className="blog-card-content text-gray-600 mb-3"
                      dangerouslySetInnerHTML={{ __html: blog.content.slice(0, 100) + '...' }}
                    />
                    <Link
                      to={`/blog/${blog._id}`}
                      className="view-details-button inline-block bg-pink-500 text-white py-2 px-4 rounded-full hover:bg-pink-600 transition"
                    >
                      Read More
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;

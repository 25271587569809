import React from 'react';

const AboutAffirmations = ({ about }) => {
  return (
    <>
      <section>
        <div ref={about} className="padding-container mt-3 flex justify-start text-red-500 px-5 py-8 md:px-20 lg:px-40">
          {/* About Heading Container */}
          <h2 className="text-2xl md:text-3xl font-bold text-pink-400 mt-0 mb-2">
            About YourOwnAffirmations.com
          </h2>
        </div>

        {/* Responsive paragraphs with padding and increased line height */}
        <div className="padding-container px-5 py-8 md:px-20 lg:px-40">
          <p className="gray-color text-xs md:text-sm lg:text-base mb-4 leading-relaxed md:leading-relaxed lg:leading-normal">
            Do you listen to YouTube pre-made affirmation soundtracks to make
            your manifestations happen? We did too! But we discovered that
            listening to your own voice does the trick so much better!
          </p>
          <p className="gray-color text-xs md:text-sm lg:text-base mb-4 leading-relaxed md:leading-relaxed lg:leading-normal">
            Most of the apps where you can create your own affirmations require
            monthly subscriptions and we don’t like that...
          </p>
          <p className="gray-color text-xs md:text-sm lg:text-base mb-4 leading-relaxed md:leading-relaxed lg:leading-normal">
            So that’s why we created this <strong>handy tool</strong> where you
            can <strong>easily record your affirmations</strong> in your own
            voice and create your own personal manifestation audio file where
            you can listen to at all times anywhere you like! Whether that is in
            the bathtub, before/during/after sleep time, on the couch, in the
            park, while working out, while skydiving, while on a boring family
            dinner, ... You catch our drift here. ;-)
          </p>
          <p className="gray-color text-xs md:text-sm lg:text-base leading-relaxed md:leading-relaxed lg:leading-normal">
            Our tool is what it says it is: a tool. It’s not an easy shortcut to
            manifesting, it makes your manifesting more powerful, but it is
            still you that has to do all the work. Believe in yourself, believe
            in the power of your imagination, and the world is at your feet!
          </p>
        </div>
      </section>
    </>
  );
};

export default AboutAffirmations;

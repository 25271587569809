import React from 'react';
import icon7 from "../../../src/assets/images/icon-7.png";
import icon5 from "../../../src/assets/images/icon-5.png";
import icon4 from "../../../src/assets/images/icon-4.png";
import icon2 from "../../../src/assets/images/icon-2.png";
import icon6 from "../../../src/assets/images/icon-6.png";

const PersonalAffirmation = ({ howItWorks, clickHere }) => {
  return (
    <section className="py-10">
      <div ref={howItWorks} className="text-center mb-10">
        <h3 
          style={{ background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)', textShadow: '2px 2px 0px #f492ed' }}
          className="text-white mt-10 mb-2 px-5 py-2 inline-block rounded-full text-2xl font-bold bg-gradient-to-r from-cyan-200 via-yellow-200 to-pink-200 shadow-lg"
        >
          Create your personal affirmation soundtrack
          <br />
          <a href="#!" onClick={clickHere} className="text-white underline">
            <strong>HERE</strong>
          </a>
        </h3>
      </div>

      <div className="container mx-auto">
        <div className="text-center mb-6">
          <h1 className="text-pink-400 text-4xl font-bold">How it Works</h1>
        </div>

        {/* First row for Steps 1, 2, 3 */}
        <div className="flex flex-wrap justify-center gap-8 mb-8 lg:px-16">
          <div className="w-full md:w-1/4 text-center p-4">
            <div className="mb-2">
              <img className="mx-auto w-20 h-20" src={icon7} alt="Step 1" />
            </div>
            <h2 className="text-pink-400 text-2xl font-bold mb-1">Step 1</h2>
            <p className="text-base">
              <strong className="text-pink-400">Record Your Affirmations</strong>
              <br />
              Use our easy-to-use online recorder to capture your affirmations in your own voice <strong>or upload a pre-recorded audio-file.</strong> Not happy? You can start over as much as you like by clicking the ‘re-record or re-upload audio’-button.
            </p>
          </div>

          <div className="w-full md:w-1/4 text-center p-4">
            <div className="mb-2">
              <img className="mx-auto w-20 h-20" src={icon5} alt="Step 2" />
            </div>
            <h2 className="text-pink-400 text-2xl font-bold mb-1">Step 2</h2>
            <p className="text-base">
              <strong className="text-pink-400">Automatic Integration</strong>
              <br />
              Once recorded/uploaded, you can choose a soundtrack. Choose ‘merge audio’ our system will automatically loop your affirmation over the 30-minute manifestation soundtrack designed to amplify your positive intentions.
            </p>
          </div>

          <div className="w-full md:w-1/4 text-center p-4">
            <div className="mb-2">
              <img className="mx-auto w-20 h-20" src={icon4} alt="Step 3" />
            </div>
            <h2 className="text-pink-400 text-2xl font-bold mb-1">Step 3</h2>
            <p className="text-base">
              <strong className="text-pink-400">Pre-listen</strong>
              <br />
              A 10-second pre-listen audio is generated. You can check if you like how it sounds and adjust the volume of both your recording/upload and the soundtrack. Happy with the result? Click the ‘create full audio affirmation’-button.
            </p>
          </div>
        </div>

        {/* Second row for Steps 4, 5 */}
        <div className="flex flex-wrap justify-center gap-8 lg:px-16">
          <div className="w-full md:w-1/4 text-center p-4">
            <div className="mb-2">
              <img className="mx-auto w-20 h-20" src={icon2} alt="Step 4" />
            </div>
            <h2 className="text-pink-400 text-2xl font-bold mb-1">Step 4</h2>
            <p className="text-base">
              <strong className="text-pink-400">Secure Payment</strong>
              <br />
              After the merging is done (this can take a few minutes) and before downloading, you can pay for the recording via the secure payment process.
            </p>
          </div>

          <div className="w-full md:w-1/4 text-center p-4">
            <div className="mb-2">
              <img className="mx-auto w-20 h-20" src={icon6} alt="Step 5" />
            </div>
            <h2 className="text-pink-400 text-2xl font-bold mb-1">Step 5</h2>
            <p className="text-base">
              <strong className="text-pink-400">Download Custom Audio</strong>
              <br />
              After successful payment, you'll be able to download your personalized audio track and start using it immediately. Manifesting time baby!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalAffirmation;

import React from 'react';
import { Link } from 'react-router-dom';

import youtube from "../../../src/assets/images/youtube.png";
import instagram from "../../../src/assets/images/instagram.png";
import facebook from "../../../src/assets/images/facebook.png";
const Footer = () => {
  return (
    <section>
      <footer
        className="flex flex-col md:flex-row items-center justify-center p-5 text-white font-sans"
        style={{ background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)' }}
      >
        <div className="text-center font-bold">
          <div>
            <p className="text-sm md:text-lg mb-0">
              The Dream Miners bv <br />
              Houtheide 32, 3150 Haacht, BE <br />
              VAT: BE0788987013
            </p>
            <p className="text-lg md:text-2xl mt-0">
              © 2024 yourownaffirmations.com &nbsp; &nbsp;
              <Link to="/terms-and-conditions" className="text-white no-underline">Terms and conditions</Link>
              &nbsp; | &nbsp;
              <Link to="/privacy-policy" className="text-white no-underline cursor-pointer">Privacy Policy</Link>
            </p>
          </div>
        </div>

        <div className="flex lg:ml-36">
          <div className="text-lg font-bold text-center  mt-5 md:mt-0">
            <p className="text-sm md:text-lg">Follow us</p>
            <div className="flex justify-center md:justify-end mt-2">
              <a href="https://www.youtube.com/channel/UCbJnE5KujiLoq940y_-WHFg" target="_blank" rel="noopener noreferrer">
                <img className="w-10 h-10 rounded-lg mr-2" src={youtube} alt="youtube" />
              </a>
              <a href="https://www.instagram.com/yourownaffirmations/" target="_blank" rel="noopener noreferrer">
                <img className="w-10 h-10 rounded-lg mr-2" src={instagram} alt="instagram" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61559554287269" target="_blank" rel="noopener noreferrer">
                <img className="w-10 h-10 rounded-lg" src={facebook} alt="facebook" />
              </a>
            </div>
          </div>
        </div>

      </footer>
    </section>
  );
}

export default Footer;
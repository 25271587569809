import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { VscThreeBars } from 'react-icons/vsc'; // Import the hamburger icon
import logo from "../../../src/assets/images/logo.jpeg";

const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false); // State to control mobile menu visibility

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = (event, section) => {
    event.preventDefault();
    scrollToTop();
    setMobileMenuOpen(false); // Close the menu
    if (location.pathname === '/') {
      window.location.hash = section;
    } else {
      window.location.href = `/${section}`;
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu visibility
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isMobileMenuOpen && !event.target.closest('.mobile-dropdown') && !event.target.closest('.mobile-menu-icon')) {
        setMobileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isMobileMenuOpen]);

  return (
    <div className="fixed top-0 w-full px-5 bg-white shadow-lg z-50">
      <header className="flex justify-between items-center py-4">
        <Link to="/" onClick={scrollToTop} className="flex items-center">
          <img className="h-11 md:w-96 " src={logo} alt="logo" />
        </Link>
        <div className="mobile-menu-icon cursor-pointer md:hidden" onClick={toggleMobileMenu}>
          <VscThreeBars size={30} className="text-gray-800" />
        </div>
        <nav className="hidden md:flex items-center space-x-4 md:space-x-6 lg:space-x-16 ml-auto"> {/* Responsive spacing */}
          <a href="#about" onClick={(e) => handleClick(e, '#about')} className="text-pink-500 text-xl hover:text-pink-700 transition duration-300 font-semibold">About</a>
          <a href="#how-it-works" onClick={(e) => handleClick(e, '#how-it-works')} className="text-pink-500 text-xl hover:text-pink-700 transition duration-300 font-semibold">How it works</a>
          <a href="#start-creating" onClick={(e) => handleClick(e, '#start-creating')} className="text-pink-500 text-xl hover:text-pink-700 transition duration-300 font-semibold">Start creating</a>
          <Link to="/blogs" className="text-pink-500 text-xl hover:text-pink-700 transition duration-300 font-semibold">Blog</Link>
          <a href="#contact" onClick={(e) => handleClick(e, '#contact')} className="text-pink-500 text-xl hover:text-pink-700 transition duration-300 font-semibold">Contact</a>
        </nav>

      </header>

      {/* Mobile Menu (Dropdown) */}
      {isMobileMenuOpen && (
        <div className="absolute top-16 right-5 bg-white shadow-lg rounded-lg p-4 z-50 mobile-dropdown w-48">
          <a href="#about" onClick={(e) => handleClick(e, '#about')} className="block py-2 text-pink-500 text-lg hover:bg-gray-200 transition duration-300 rounded">About</a>
          <a href="#how-it-works" onClick={(e) => handleClick(e, '#how-it-works')} className="block py-2 text-pink-500 text-lg hover:bg-gray-200 transition duration-300 rounded">How it works</a>
          <a href="#start-creating" onClick={(e) => handleClick(e, '#start-creating')} className="block py-2 text-pink-500 text-lg hover:bg-gray-200 transition duration-300 rounded">Start creating</a>
          <Link to="/blogs" className="block py-2 text-pink-500 text-lg hover:bg-gray-200 transition duration-300 rounded">Blog</Link>
          <a href="#contact" onClick={(e) => handleClick(e, '#contact')} className="block py-2 text-pink-500 text-lg hover:bg-gray-200 transition duration-300 rounded">Contact</a>
        </div>
      )}
    </div>
  );
};

export default Navbar;
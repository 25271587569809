import React, { useRef, useState } from 'react';
import PredefinedAudios from './PredefinedAudios';
import MergePlayAudio from '../MergePlayAudio/MergePlayAudio';
import AudioPlayer from './AudioPlayer.js';
import pause from "../../../src/assets/images/pauseimg12.png";
import play from "../../../src/assets/images/playimg.png";
import stop from "../../../src/assets/images/stopimg.png";
import { audioOptions } from '../utils.js';
import { REACT_APP_BASE_URL } from '../../config.js';
import axios from 'axios';
import { BlobToActualFile, blobToFile, bufferToWave } from '../../commonFunctions/commonFunctions.js';

const Application = ({
  bottomRef,
  audioFile,
  setAudioFile,
  recordedAudio,
  setRecordedAudio,
  isRecording,
  setIsRecording,
}) => {
  const [volumePercentage1, setVolumePercentage1] = useState(100);
  const [volumePercentage2, setVolumePercentage2] = useState(100);
  const [convertFile, setConvertFile] = useState(null);
  const [showMergeButton, setShowMergeButton] = useState(false);

  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [isRecordingPulse, setIsRecordingPulse] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showRecord, setShowRecord] = useState(true);
  const [showUpload, setShowUpload] = useState(true);
  const [selectedPredefinedAudio, setSelectedPredefinedAudio] = useState(null);


  const handleAudioFileChange = async (event) => {
    const file = event.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      alert('Error: File size exceeds the maximum limit of 2 MB.');
      return; 
    }

    if (file && file.type === 'audio/m4a') {
      alert('Error: The audio file format "m4a" is not supported.');
      return; 
    }

    const formData = new FormData();
    formData.append('audio', file);

    try {
      const response = await axios.post(`${REACT_APP_BASE_URL}/upload-audio`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAudioFile(response.data.url); 
      setRecordedAudio(null);
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
  };



  const handleStopRecording = () => {
    if (isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      setIsRecordingPulse(false);
      setIsPaused(false);
    }
  };
  // const handleRecording = () => {
  //   if (isRecording) {
  //     if (isPaused) {
  //       mediaRecorder.current.resume();
  //       setIsPaused(false);
  //     } else {
  //       mediaRecorder.current.pause();
  //       setIsPaused(true);
  //     }
  //   } else {
  //     navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
  //       mediaRecorder.current = new MediaRecorder(stream);
  //       mediaRecorder.current.ondataavailable = (event) => chunks.current.push(event.data);
  //       mediaRecorder.current.onstop = async () => {
  //         const blob = new Blob(chunks.current, { type: 'audio/wav' });
  //         await uploadRecordedAudio(blob); // Call the upload function
  //         chunks.current = [];
  //         setShowRecord(true);
  //         setShowUpload(true);
  //       };
  //       mediaRecorder.current.start();
  //       setIsRecording(true);
  //       setIsRecordingPulse(true);
  //       setIsPaused(false);
  //     });
  //     setShowUpload(false);
  //   }
  // };

  // const uploadRecordedAudio = async (audioBlob) => {
  //   const file = new File([audioBlob], 'recorded-audio.wav', { type: 'audio/wav' });
  //   const formData = new FormData();
  //   formData.append('audio', file);

  //   try {
  //     const response = await axios.post(`${REACT_APP_BASE_URL}/upload-audio`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     console.log('Upload response:', response.data);
  //     if (response.data.url) {
  //       setRecordedAudio(response.data.url);
  //     } else {
  //       console.error('No URL returned from upload');
  //     }
  //   } catch (error) {
  //     console.error('Error uploading recorded audio:', error);
  //   }
  // };


  const handleRecording = () => {
    if (isRecording) {
      if (isPaused) {
        mediaRecorder.current.resume();
        setIsPaused(false);
      } else {
        mediaRecorder.current.pause();
        setIsPaused(true);
      }
    } else {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event) => chunks.current.push(event.data);
        mediaRecorder.current.onstop = async () => {
          // Create a Blob in WebM format
          const blob = new Blob(chunks.current, { type: 'audio/webm' });
          await uploadRecordedAudio(blob); // Call the upload function
          chunks.current = [];
          setShowRecord(true);
          setShowUpload(true);
        };
        mediaRecorder.current.start();
        setIsRecording(true);
        setIsRecordingPulse(true);
        setIsPaused(false);
      });
      setShowUpload(false);
    }
  };

  const uploadRecordedAudio = async (audioBlob) => {

    // const file = blobToFile(audioBlob,'audio.wav');
    const file = await BlobToActualFile(audioBlob);
    console.log("file>>>>>>>", file)
    const formData = new FormData();
    formData.append('audio', file);

    try {
      const response = await axios.post(`${REACT_APP_BASE_URL}/upload-audio`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Upload response:', response.data);
      if (response.data.url) {
        setRecordedAudio(response.data.url);
      } else {
        console.error('No URL returned from upload');
      }
    } catch (error) {
      console.error('Error uploading recorded audio:', error);
    }
  };



  const handleReRecord = () => {
    setRecordedAudio(null);
    setAudioFile(null);
  };


  const handleAudioSelect = (selectedAudio) => {
    setSelectedPredefinedAudio(selectedAudio);
    setConvertFile(null);
  };

  return (
    <>
      <section>
        <div ref={bottomRef} className="text-4xl flex justify-center text-red-500 text-center">
          <h3 className="affirmation-heading font-bold">
            Create Your Personal Affirmation Magic <br /> here and now:
          </h3>
        </div>

        {recordedAudio || audioFile ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <div style={{ justifyContent: 'space-between', alignItems: 'center', width: '90%' }}>
              <AudioPlayer recordedAudio={recordedAudio} audioFile={audioFile} volume={volumePercentage1} />
            </div>
          </div>

        ) : (
          <div className='voice-actions-container'>
            {showRecord && (
              <div
                className='record'
                style={{
                  fontSize: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'red',
                  textAlign: 'center',
                  marginRight: '20px',
                }}
              >
                <h3
                  className="text-white align-self-center mt-10 mb-2 py-16 px-10 rounded-full cursor-pointer bg-gradient-to-r from-teal-200 to-yellow-300 font-bold"
                  onClick={handleRecording}
                >
                  {!isRecordingPulse ? 'RECORD VOICE' : (
                    <div className="flex items-center">
                      <span className="recording-pulse"></span>
                      <span className="ml-2">Recording...</span>
                    </div>
                  )}
                </h3>

              </div>
            )}     {isRecording && (
              <>
                {isPaused ? (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      opacity: 1,
                      cursor: 'pointer',
                      marginTop: '35px',
                      marginLeft: '35px'
                    }}
                    src={play}
                    alt="Play icon"
                    onClick={handleRecording}
                  />
                ) : (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      opacity: 1,
                      cursor: 'pointer',
                      marginTop: '35px',
                      marginLeft: '35px'
                    }}
                    src={pause}
                    alt="Pause icon"
                    onClick={handleRecording}
                  />
                )}
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    opacity: 1,
                    cursor: 'pointer',
                    marginTop: '35px',
                    marginLeft: '35px'
                  }}
                  src={stop}
                  alt="Stop icon"
                  onClick={handleStopRecording}
                />
              </>
            )}
            {showUpload && (
              <div
                style={{
                  fontSize: '30px',
                  color: '#f492ed',
                  margin: '0 20px',
                  padding: '10px 20px',
                  borderRadius: '10px',
                  marginTop: '25px',
                  // fontFamily: 'cursive',

                }}
              >
                OR
              </div>
            )}
            {showUpload && (
              <div
                className='recording'
                style={{
                  fontSize: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'red',
                  textAlign: 'center',
                  marginLeft: '20px',
                }}
              >
                <h3
                  className="text-white mt-10 mb-2 py-12 px-10 rounded-full cursor-pointer bg-gradient-to-r from-teal-200 to-yellow-300 font-bold"
                >
                  <label htmlFor="fileInput">
                    UPLOAD <br />
                    <span className="font-bold">VOICE RECORDING</span>
                  </label>
                  <input
                    type="file"
                    onChange={handleAudioFileChange}
                    accept="audio/*"
                    className="hidden"
                    id="fileInput"
                  />
                </h3>


              </div>
            )}
          </div>
        )}
        {(recordedAudio || audioFile) && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <div style={{ width: '90%', textAlign: 'center' }}>
              <button
                style={{
                  padding: '10px 20px',
                  background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={handleReRecord}
              >
                Re-record or Re-upload Audio
              </button>
            </div>
          </div>
        )}
        <div
          style={{
            fontSize: "25px",
            display: "flex",
            justifyContent: "center",
            color: "red",
            textAlign: "center",
          }}
        >
          <h1
            className="pink-color mt-10 mb-2 py-2 px-5 rounded-lg font-bold"
          >
            Choose your favourite manifestation soundtrack
          </h1>

        </div>

        <PredefinedAudios
          audioOptions={audioOptions}
          onSelect={handleAudioSelect}
          volume={volumePercentage2}
        />

        {(recordedAudio || audioFile) && selectedPredefinedAudio && (
          <>

            {console.log('reeeeeee', recordedAudio)}
            <MergePlayAudio
              audioFile1={selectedPredefinedAudio}
              audioFile2={audioFile || recordedAudio}
              volumePercentage1={volumePercentage1}
              volumePercentage2={volumePercentage2}
              // convertFile={convertFile}
              setShowMergeButton={setShowMergeButton}
            />


          </>
        )}
      </section>
    </>
  );
};

export default Application;

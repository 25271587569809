import React from 'react';
import icon9 from "../../../src/assets/images/icon-9.png";
import icon8 from "../../../src/assets/images/icon-8.png";

const Contact = ({ contact }) => {
  return (
    <>
      <section>
        {/* Contact Heading Container */}
        <div
          ref={contact}
          className="padding-container mt-3 flex justify-start text-red-500 text-center px-5 py-8"
        >
          <h2 className="text-2xl md:text-3xl font-bold text-pink-400 mt-0 mb-2">
            Contact
          </h2>
        </div>

        {/* Contact Steps */}
        <div className="steps flex flex-col items-center md:flex-row justify-center gap-8 mt-5">
          <a
            href="mailto:hello@yourownaffirmations.com"
            className="step flex flex-col items-center text-center text-xl md:text-2xl text-pink-500 font-semibold hover:text-pink-600 transition"
          >
            <div className="icon mb-3">
              <img className="contact-icon w-16 h-16 md:w-20 md:h-20" src={icon9} alt="Email Icon" />
            </div>
            <h2>Send us an email</h2>
          </a>

          {/* Optional Chat Section (Commented Out) */}
          {/* <div className="step flex flex-col items-center text-center text-xl md:text-2xl text-pink-500 font-semibold hover:text-pink-600 transition">
            <div className="icon mb-3">
              <img className="contact-icon w-16 h-16 md:w-20 md:h-20" src={icon8} alt="Chat Icon" />
            </div>
            <h2 className="color">Chat with us</h2>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Contact;

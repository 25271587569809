import React, { useState, useEffect, useRef } from 'react';

const CustomLoader = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

const AudioPlayer = ({ recordedAudio, audioFile, volume }) => {
  const [isLoadingRecordedAudio, setIsLoadingRecordedAudio] = useState(false);
  const [isLoadingUploadedAudio, setIsLoadingUploadedAudio] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (recordedAudio) {
      setIsLoadingRecordedAudio(true);
      setTimeout(() => {
        setIsLoadingRecordedAudio(false);
      }, 1000);
    }
  }, [recordedAudio]);

  useEffect(() => {
    if (audioFile) {
      setIsLoadingUploadedAudio(true);
      setTimeout(() => {
        setIsLoadingUploadedAudio(false);
      }, 1000);
    }
  }, [audioFile]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  return (
    <div className="flex flex-col items-center justify-center w-full p-4">
      {isLoadingRecordedAudio || isLoadingUploadedAudio ? (
        <CustomLoader size={50} color="#f492ed" />
      ) : (
        <>
          {recordedAudio && (
            <div className="w-full sm:w-3/4 md:w-2/4 lg:w-1/3 p-5 bg-white rounded-lg shadow-lg text-center mb-5">
              <h2 className="mb-3 text-xl font-semibold text-pink-500">Recorded Audio:</h2>
              <audio
                ref={audioRef}
                controls
                className="w-full outline-none rounded-md"
              >
                <source src={recordedAudio} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}

          {audioFile && (
            <div className="w-full sm:w-3/4 md:w-2/4 lg:w-1/3 p-5 bg-white rounded-lg shadow-lg text-center mb-5">
              <h2 className="mb-3 text-xl font-semibold text-pink-500">Uploaded Audio:</h2>
              <audio
                ref={audioRef}
                controls
                className="w-full outline-none rounded-md"
              >
                <source src={audioFile} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AudioPlayer;

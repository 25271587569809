
// Function to create a new audio file with a specified duration
export const createLongAudio = async (audioFile, targetDurationSeconds) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  console.log('audiouuuuukkkkkkk,,,,,,,', audioFile)
  const arrayBuffer = await audioFile.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const durationSeconds = audioBuffer.duration;
  const repeatCount = Math.ceil(targetDurationSeconds / durationSeconds);
  const newBuffer = audioContext.createBuffer(
    audioBuffer.numberOfChannels,
    audioBuffer.length * repeatCount,
    audioBuffer.sampleRate
  );

  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = newBuffer.getChannelData(channel);
    for (let i = 0; i < repeatCount; i++) {
      channelData.set(audioBuffer.getChannelData(channel), i * audioBuffer.length);
    }
  }

  const finalLength = Math.min(newBuffer.length, audioBuffer.sampleRate * targetDurationSeconds);
  const trimmedBuffer = audioContext.createBuffer(
    newBuffer.numberOfChannels,
    finalLength,
    newBuffer.sampleRate
  );

  for (let channel = 0; channel < newBuffer.numberOfChannels; channel++) {
    trimmedBuffer.copyToChannel(newBuffer.getChannelData(channel).subarray(0, finalLength), channel);
  }

  const offlineContext = new OfflineAudioContext(
    trimmedBuffer.numberOfChannels,
    trimmedBuffer.length,
    trimmedBuffer.sampleRate
  );
  const source = offlineContext.createBufferSource();
  source.buffer = trimmedBuffer;
  source.connect(offlineContext.destination);
  source.start();

  const renderedBuffer = await offlineContext.startRendering();
  const wavBlob = bufferToWave(renderedBuffer);
  return blobToFile(wavBlob, 'long-audio.mp3');
};
export const trimAudio = async (audioFile, targetDurationSeconds) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  let arrayBuffer;

  // Check if audioFile is a File or Blob
  if (audioFile instanceof Blob || audioFile instanceof File) {
    arrayBuffer = await audioFile.arrayBuffer();
  } else if (typeof audioFile === 'object' && audioFile.src) {
    // If it's an object with a src property (assumed to be your { src } object)
    const response = await fetch(audioFile.src);
    if (!response.ok) {
      throw new Error(`Failed to fetch audio file: ${response.status} ${response.statusText}`);
    }
    arrayBuffer = await response.arrayBuffer();
  } else {
    throw new Error('Invalid audio file provided.');
  }

  // Decode audio data
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Calculate trimming parameters and create new buffer
  const durationSeconds = audioBuffer.duration;
  const repeatCount = Math.ceil(targetDurationSeconds / durationSeconds);
  const newBuffer = audioContext.createBuffer(
    audioBuffer.numberOfChannels,
    audioBuffer.length * repeatCount,
    audioBuffer.sampleRate
  );

  // Copy original audio data into the new buffer
  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = newBuffer.getChannelData(channel);
    for (let i = 0; i < repeatCount; i++) {
      channelData.set(audioBuffer.getChannelData(channel), i * audioBuffer.length);
    }
  }

  // Create a trimmed buffer based on the target duration
  const finalLength = Math.min(newBuffer.length, audioBuffer.sampleRate * targetDurationSeconds);
  const trimmedBuffer = audioContext.createBuffer(
    newBuffer.numberOfChannels,
    finalLength,
    newBuffer.sampleRate
  );

  // Copy data from newBuffer to trimmedBuffer
  for (let channel = 0; channel < newBuffer.numberOfChannels; channel++) {
    trimmedBuffer.copyToChannel(newBuffer.getChannelData(channel).subarray(0, finalLength), channel);
  }

  // Render trimmed audio asynchronously
  const offlineContext = new OfflineAudioContext(
    trimmedBuffer.numberOfChannels,
    trimmedBuffer.length,
    trimmedBuffer.sampleRate
  );
  const source = offlineContext.createBufferSource();
  source.buffer = trimmedBuffer;
  source.connect(offlineContext.destination);
  source.start();

  const renderedBuffer = await offlineContext.startRendering();
  const wavBlob = bufferToWave(renderedBuffer);
  return blobToFile(wavBlob, 'trimmed-audio.mp3');
};


// Convert an AudioBuffer to a WAV Blob
export const bufferToWave = (abuffer) => {
  const numOfChan = abuffer.numberOfChannels;
  const length = abuffer.length * numOfChan * 2 + 44;
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);
  let pos = 0;

  // Write WAV header
  const setUint32 = (data) => {
    view.setUint32(pos, data, true);
    pos += 4;
  };
  const setUint16 = (data) => {
    view.setUint16(pos, data, true);
    pos += 2;
  };

  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // File length - 8
  setUint32(0x45564157); // "WAVE"
  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16);         // Length = 16
  setUint16(1);          // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan); // Avg. bytes/sec
  setUint16(numOfChan * 2); // Block-align
  setUint16(16); // 16-bit

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // Chunk length

  // Write interleaved data
  const channels = [];
  for (let i = 0; i < numOfChan; i++) {
    channels.push(abuffer.getChannelData(i));
  }

  let offset = 0;
  while (pos < length) {
    for (let i = 0; i < numOfChan; i++) {
      const sample = Math.max(-1, Math.min(1, channels[i][offset])); // Clamp
      view.setInt16(pos, (sample * 32767) | 0, true); // Write 16-bit sample
      pos += 2;
    }
    offset++;
  }

  return new Blob([buffer], { type: 'audio/wav' });
};

// Convert a Blob or File into an AudioBuffer and return a WAV Blob
export const BlobToActualFile = async (audioFile) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
  if (!(audioFile instanceof Blob || audioFile instanceof File)) {
    throw new Error('Invalid audio file provided.');
  }

  const arrayBuffer = await audioFile.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
  // Render trimmed audio asynchronously
  const offlineContext = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);
  const source = offlineContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(offlineContext.destination);
  source.start();

  const renderedBuffer = await offlineContext.startRendering();
  const wavBlob = bufferToWave(renderedBuffer);
  return blobToFile(wavBlob, 'audio.mp3');
};

// Convert a Blob to a File with a specified filename
export const blobToFile = (blob, filename) => {
  return new File([blob], filename, { type: 'audio/mp3' });
};

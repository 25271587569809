import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { REACT_APP_BASE_URL } from '../../config';

const Record = () => {
  const [priceData, setPriceData] = useState({ price: 0, currency: '' });

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const response = await axios.get(`${REACT_APP_BASE_URL}/price`);

        if (response.data.success && response.data.data.length > 0) {
          const priceInfo = response.data.data[0];
          setPriceData({ price: priceInfo.price, currency: priceInfo.currency });
        }
      } catch (error) {
        console.error('Error fetching price data:', error);
      }
    };

    fetchPriceData();
  }, []);
  const oldPrice = Math.floor(priceData.price * 1.3) + 0.99;


  return (
    <section
      className="relative  flex flex-col items-center justify-center text-center mb-5 mt-16"
      style={{ background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)' }}
    >
      <h1 className="text-5xl font-bold text-white p-2 mt-16 mb-5 " style={{ textShadow: '5px 5px 0px #f492ed' }}>
        Record Your Own Affirmations
      </h1>
      <div className="bg-white shadow-md w-full max-w-xs sm:max-w-2xl mb-16 p-4 md:p-2 relative rounded-lg sm:rounded-none">
        <p className=" md:mb-3 md:mt-2  mt-0 mb-7 text-sm sm:text-lg text-gray-600">
          Welcome to our unique platform where you can <br className="hidden sm:block" />
          <strong>record your personal affirmations</strong> and have them <br className="hidden sm:block" />
          seamlessly <strong>integrated and looped</strong> into a{' '}
          <strong>
            30-minute <br className="hidden sm:block" /> manifestation soundtrack
          </strong>{' '}
          of your choosing. Harness the <br className="hidden sm:block" /> power of your own voice combined with our specially{' '}
          <br className="hidden sm:block" /> designed background music to enhance your <br className="hidden sm:block" /> manifestation practice.
        </p>
        <div className="absolute bottom-[-70px] left-[-35px] sm:bottom-[-125px] sm:left-[-100px] w-32 h-32 sm:w-48 sm:h-48 p-5 sm:p-3 bg-pink-400 rounded-full flex flex-col items-center justify-center text-white text-center text-xs sm:text-sm">
          <div className="text-[26px] sm:text-[36px] font-bold mb-6 mt-4">Pricing</div>
          <div className="old-price line-through text-xs sm:text-sm mb-1">{oldPrice} $</div>
          <div className="new-price text-lg sm:text-xl font-semibold">{priceData.price.toFixed(2)} $</div>
        </div>
      </div>
    </section>
  );
};

export default Record;


import React from 'react';

const DownloadAudioButton = ({ audioUrl }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(audioUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const timestamp = new Date().toISOString().replace(/:/g, '-');
      const filename = `affirmation_audio_${timestamp}.mp3`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed', error);
    }
  };

  return (
    <div>
      <button
        onClick={handleDownload}
        style={{
            marginTop:'20px',
          background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)', // Gradient background
          color: 'white', // White text
          padding: '10px 20px', // Padding
          border: 'none', // No border
          borderRadius: '5px', // Rounded corners
          cursor: 'pointer', // Pointer cursor on hover
          fontSize: '16px', // Font size
        }}
      >
        Download Audio
      </button>
    </div>
  );
};

export default DownloadAudioButton;

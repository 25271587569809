import React from 'react';

const OurTool = ({ blog }) => {
  return (
    <>
      <section>
        <div
          ref={blog}
          className="tool-benefits text-center px-5 py-8 bg-gradient-to-r from-cyan-200 via-yellow-200 to-pink-300"
        >
          {/* Reduced font size for title and ensured responsiveness */}
          {/* <h2 className="title text-xl md:text-2xl font-bold mb-5 mt-0 text-white drop-shadow-md"> */}
          <h2
          style={{ textShadow: '5px 5px 0px #f492ed' }}
          className="text-white mt-10 mb-2 px-5 py-2 inline-block rounded-full text-6xl font-bold "
        >
            Why you should use our tool
          </h2>

          <div className="benefits-container flex flex-col md:flex-row justify-center gap-5 md:gap-9">
            
            {/* Benefit Box 1 */}
            <div className="benefit bg-white rounded-3xl w-full md:w-1/4 shadow-md mx-2 p-6">
              <div className="p-4">
                <h4 className="benefit-title text-xl font-bold text-pink-400 mb-3">
                  Personalized Experience
                </h4>
                <p className="gray-color text-lg md:text-xl">
                  Your affirmations in <br />
                  your <strong>own voice</strong> are{" "}
                  <strong>more <br /> powerful and meaningful</strong>, creating a deeply personal manifestation tool.
                </p>
              </div>
            </div>

            {/* Benefit Box 2 */}
            <div className="benefit bg-white rounded-3xl w-full md:w-1/4 shadow-md mx-2 p-6">
              <div className="p-4">
                <h4 className="benefit-title text-xl font-bold text-pink-400 mb-3">
                  High-Quality Soundtrack
                </h4>
                <p className="gray-color text-lg md:text-xl">
                  Our{" "}
                  <strong>professional-grade <br /> manifestation soundtrack</strong> is crafted to support relaxation, focus, and positive energy.
                </p>
              </div>
            </div>

            {/* Benefit Box 3 */}
            <div className="benefit bg-white rounded-3xl w-full md:w-1/4 shadow-md mx-2 p-6">
              <div className="p-4">
                <h4 className="benefit-title text-xl font-bold text-pink-400 mb-3">
                  Instant Access
                </h4>
                <p className="gray-color text-lg md:text-xl">
                  Get your custom audio immediately after purchase with a <strong>one-time cost</strong>, and start manifesting your dreams right away.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTool;

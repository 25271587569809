import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { APP_BASE_URL, REACT_APP_BASE_URL } from '../../config';

const BlogDetailPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const defaultImage = 'https://deep-image.ai/blog/content/images/2024/04/e2912cf3-9b89-4092-a821-6f4978e95911-generated.webp';

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${REACT_APP_BASE_URL}/blogs/${id}`);
        if (response.data.success) {
          setBlog(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };

    fetchBlog();
  }, [id]);

  const handleImageError = (event) => {
    event.target.src = defaultImage;
  };

  if (!blog) return <p className="text-center text-lg mt-20">Loading...</p>;

  return (
    <div className="mt-24 max-w-7xl mx-auto p-5">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex justify-center items-center p-5">
          <img
            src={blog?.images ? `${APP_BASE_URL}/${blog.images}` : defaultImage}
            alt={blog.title}
            onError={handleImageError}
            className="w-full h-80 object-cover rounded-lg"
          />
        </div>

        <h2 className="text-3xl font-bold text-pink-500 text-center my-5">
          {blog.title}
        </h2>

        <div
          id="editor-containers"
          className="text-gray-700 leading-relaxed p-5"
          dangerouslySetInnerHTML={{
            __html: blog.content,
          }}
        />
      </div>
    </div>
  );
};

export default BlogDetailPage;

import React, { useEffect, useState } from 'react';
import { CustomLoader } from '../All Components/customLoader';
import ReactAudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import axios from 'axios';
import { APP_BASE_URL, REACT_APP_BASE_URL } from '../../config';
import { useLocation } from 'react-router-dom';
import DownloadAudioButton from '../DownloadAudioButton';

const PaymentSuccess = () => {
    const [isMerging, setIsMerging] = useState(true);
    const [mergedAudioUrl, setMergedAudioUrl] = useState(null);
    const [email, setEmail] = useState('');

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionId = params.get('sessionId');
        const paymentId = params.get('paymentId');
        const payerId = params.get('PayerID');

        console.log('Retrieved sessionId:', sessionId);

        if (sessionId, paymentId, payerId) {
            mergeAudios(sessionId, paymentId, payerId);
        } else {
            console.error('No sessionId found in URL.');
            setIsMerging(false);
        }
    }, [location.search, ]);

    const mergeAudios = async (sessionId, paymentId, payerId) => {
        try {
            const response = await axios.post(`${REACT_APP_BASE_URL}/merge/${sessionId}`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data;
            setMergedAudioUrl(data.url);
            setIsMerging(false);
            console.log('data.success,,,', data, data.success)
            if (data.success && paymentId && payerId && sessionId) {
                await axios.get(`${REACT_APP_BASE_URL}/success`, {
                    params: {
                        paymentId: paymentId,
                        PayerID: payerId,
                        sessionId: sessionId
                    }
                });
            }


        } catch (error) {
            console.error('Error merging audio:', error);
            setIsMerging(false);

            // await axios.get(`${REACT_APP_BASE_URL}/paypal-cancel`);
        }
    };

  

    return (
        <div style={styles.container}>
            <div style={styles.iconContainer}>
                <span style={styles.checkIcon}>✔️</span>
            </div>
            <h2 style={styles.title}>Thank You for Your Payment!</h2>
            <p style={styles.message}>Your transaction has been processed successfully.</p>
            <div style={styles.waitContainer}>
                <p style={styles.waitText}>Please wait, your complete affirmation is being made and will be available to you very soon.</p>
            </div>
            {isMerging ? (
                <CustomLoader style={{ marginTop: '10px' }} size={45} color="#f492ed" />
            ) : (
                <>
                    {console.log('mergedAudioUrl', mergedAudioUrl)}
                    {mergedAudioUrl && (
                        <div style={{ marginTop: '20px', width: '70%', position: 'relative' }}>
                            <ReactAudioPlayer
                                src={mergedAudioUrl}
                                controls
                                style={styles.audioPlayer}
                            />
                            {/* Download Button */}
                            <DownloadAudioButton audioUrl={mergedAudioUrl}/>
                         
                        </div>
                    )}
                    <a href="/" style={styles.button}>Back to Home</a>
                    {/* <div style={styles.emailContainer}>
                        <form onSubmit={handleEmailSubmit} style={styles.emailForm}>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email to receive the audio file"
                                style={styles.emailInput}
                            />
                            <button type="submit" style={styles.emailButton}>Send</button>
                        </form>
                    </div> */}
                </>
            )}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: '20px',
    },
    iconContainer: {
        marginBottom: '10px',
    },
    checkIcon: {
        fontSize: '50px',
        color: 'green',
    },
    title: {
        fontSize: '24px',
        marginBottom: '10px',
    },
    message: {
        fontSize: '18px',
        marginBottom: '20px',
    },
    waitContainer: {
        marginTop: '20px',
        textAlign: 'center',
    },
    waitText: {
        fontSize: '22px',
        marginBottom: '10px',
    },
    button: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        textDecoration: 'none',
        fontSize: '16px',
        marginTop: '20px'
    },
    emailContainer: {
        marginTop: '20px',
        textAlign: 'center',
    },
    emailForm: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emailInput: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        marginRight: '10px',
        width: '300px',
    },
    emailButton: {
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#4CAF50',
        color: 'white',
        cursor: 'pointer',
    },
    audioPlayer: {
        margin: '0 auto', // Center the player horizontally
    },
    downloadText: {
        cursor: 'pointer',
        color: '#1E90FF',
        fontSize: '16px',
        marginTop: '10px',
        textDecoration: 'underline',
    }
};

export default PaymentSuccess;

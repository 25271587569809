import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { REACT_APP_BASE_URL } from '../config';

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [heading, setHeading] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      try {
        const response = await  axios.get(`${REACT_APP_BASE_URL}/terms-and-conditions`); 
        if (response.data.success) {
          setTermsAndConditions(response.data.data.content);
          setHeading(response.data.data.heading);
        } else {
          setError('Failed to fetch terms and conditions.');
        }
      } catch (err) {
        setError('Error fetching terms and conditions.');
      } finally {
        setLoading(false);
      }
    };

    fetchTermsAndConditions();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="terms-container">
      <div className="terms-heading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ff69b4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-file-text"
        >
          <path d="M4 1v22h16V7.5L14.5 1H4z"></path>
          <polyline points="14 1 14 7 20 7"></polyline>
          <line x1="16" y1="5" x2="8" y2="5"></line>
          <polyline points="10 18 6 18 6 14"></polyline>
          <line x1="9" y1="15" x2="9" y2="21"></line>
        </svg>
        <h1>{heading}</h1>
      </div>

      <section
        className="section-content"
        dangerouslySetInnerHTML={{ __html: termsAndConditions }}
      ></section>
    </div>
  );
};

export default TermsAndConditions;
